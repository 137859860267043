import React from 'react';
import logo from '../../resources/images/logo.svg';
import './styles.scss';
let backgroundStyles = `#root::after{background:  #2c2728 !important}`;


export default function Loading() {
    return <>
        {/* <style>{backgroundStyles}</style> */}
        <div className="loader">
            {/* <div className="outer" />
            <div className="middle" /> */}
            <img src={logo} alt="" />
        </div>
    </>
}